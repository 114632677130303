import { SContent, SImage } from './LoginContent.styled'
import { PropsWithChildren } from 'react'
import { layoutGrid } from 'driverama-core/styles/common'
import Image from 'next/image'

export function LoginContent({ children }: PropsWithChildren<unknown>) {
  return (
    <main css={layoutGrid}>
      <SImage>
        <Image
          src="/images/pitstopGuy@2x.png"
          width={464}
          height={1130}
          layout="intrinsic"
        />
      </SImage>

      <SContent>{children}</SContent>
    </main>
  )
}
