import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SImage = styled.div`
  grid-column-start: col-start 10;
  grid-column-end: col-start 12;

  display: flex;
  align-items: flex-end;

  margin: ${size(12)} 0;

  @media ${media.lte('tablet')} {
    display: none;
  }
`

export const SContent = styled.div`
  grid-column-start: col-start 2;
  grid-column-end: col-start 6;
  grid-row-start: 1;

  @media ${media.lte('tablet')} {
    grid-column-start: col-start 2;
    grid-column-end: col-start 4;
  }

  @media ${media.lte('MD')} {
    padding: ${size(4)};
    grid-column-start: col-1;
    grid-column-end: col-4;
  }

  @media ${media.lte('SM')} {
    padding: ${size(0)};
    grid-column-start: col-1;
    grid-column-end: col-4;
  }
`
