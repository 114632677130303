import { loginEmployeeViaOpenId } from 'api/auth/openid'
import { LINKS } from 'constants/links'
import { POPUP_STATUS_CODES } from 'driverama-core/auth/openid/types'
import { useOpenId } from 'driverama-core/auth/openid/useOpenId'
import { isPopupError } from 'driverama-core/auth/openid/utils/isPopupError'
import {
  isEmployeeLoginForbiddenError,
  LoginErrorMetadata
} from 'driverama-core/auth/utils/isEmployeeForbiddenError'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { TextBody } from 'driverama-core/components/text/Text'
import { toast } from 'driverama-core/components/toast/Toast'
import { size } from 'driverama-core/styles/spacing'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SButton } from './LoginForm.styled'

export function LoginFormOpenID() {
  const { t } = useTranslation(['login'])
  const { login } = useOpenId()
  const router = useRouter()
  const [error, setError] = useState<LoginErrorMetadata | undefined>()

  async function handleLogout(token_id?: string) {
    await router.push(
      addSearchParams(LINKS.logout, {
        token_id
      })
    )
  }

  const form = useForm({
    mode: 'all'
  })

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form

  const onSubmit = async () => {
    if (error) {
      setError(undefined)
    }

    try {
      const loginStatus = await login()

      await loginEmployeeViaOpenId({
        authorizationCode: loginStatus.code,
        redirectUri: loginStatus.redirectURI
      })

      await router.push(LINKS.home)
    } catch (error) {
      if (isPopupError(error)) {
        switch (error.code) {
          case POPUP_STATUS_CODES.POPUP_CLOSED:
            toast({
              type: 'error',
              content: t('login:login_popup_interrupted'),
              error
            })
            return

          case POPUP_STATUS_CODES.CANNOT_OPEN:
            toast({
              type: 'error',
              content: t('login:login_popup_cannot_open'),
              error
            })
            return

          case POPUP_STATUS_CODES.COMMON_ERROR:
            toast({
              type: 'error',
              content: t('login:login_popup_common_error'),
              error
            })
            return

          default:
            toast({
              type: 'error',
              content: t('login:login_popup_common_error'),
              error
            })
            return
        }
      }

      if (isEmployeeLoginForbiddenError(error)) {
        setError(error.response.json.metadata)
      }

      toast({ type: 'error', content: t('login:error_api'), error })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <>
          <TextBody color="warning">
            {t('login:log_in_dri_api_error', { email: error.email })}
          </TextBody>
          <Spacer size={4} axis="vertical" />
        </>
      )}

      <Flex variant="column" gap={4}>
        <SButton variant="primary" type="submit" disabled={isSubmitting}>
          {t('login:log_in')}
          {isSubmitting && (
            <SpinnerCentered variant="x-small" css={{ marginLeft: size(4) }} />
          )}
        </SButton>
        {error?.email && (
          <SButton
            variant="outline"
            onClick={() => handleLogout(error?.idToken)}
          >
            {t('login:log_out')}
          </SButton>
        )}
      </Flex>
    </form>
  )
}
