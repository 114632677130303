import { Header } from 'components/header/Header'
import { Layout } from 'components/layout/Layout'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader } from 'driverama-core/components/text/Text'
import { GetStaticProps } from 'next'
import { useTranslation } from 'react-i18next'
import { LoginContent } from 'sections/login/content/LoginContent'
import { LoginForm } from 'sections/login/form/LoginForm'
import { getIntlProps } from 'utils/intl'
import { LoginFormOpenID } from '../sections/login/form/LoginFormOpenID'

export default function Page() {
  const { t } = useTranslation(['login'])

  return (
    <Layout header={<Header variant="transparent" />}>
      <Spacer size={12} />

      <LoginContent>
        <Spacer axis="vertical" size={12} />

        <TextHeader variant={['h3', 'h2']} as="h2">
          {t('login:title')}
        </TextHeader>

        <Spacer axis="vertical" size={[8, 12]} />

        {driverama.flags.IS_KEYCLOAK_ENABLED ? (
          <LoginFormOpenID />
        ) : (
          <LoginForm />
        )}
      </LoginContent>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async ctx => ({
  props: await getIntlProps(ctx, ['login'])
})
